import { forwardRef, Ref } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export const ConditionValInput = forwardRef((props: TextFieldProps, ref: Ref<HTMLInputElement>) => (
  <TextField
    type="number"
    required
    inputProps={{ min: 0, step: '0.01' }}
    {...props}
    ref={ref}
    error={props.error}
  />
));
