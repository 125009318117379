import { NotificationRuleDevice } from '@appTypes/notificationRules/fields';
import { z } from 'zod';

const editRuleFormSchema = z
  .object({
    is_active: z.enum(['1', '2']),
    emails_list: z
      .string()
      .optional()
      .refine(
        (value) => !value || value.split(',').every((email) => /\S+@\S+\.\S+/.test(email.trim())),
        {
          message: 'Invalid email format',
        },
      ),
    site_id: z.preprocess(
      (value) => {
        if (typeof value === 'string' && value.trim() === '') {
          return undefined;
        }
        return Number(value);
      },
      z.number({
        required_error: 'Site is required',
        invalid_type_error: 'Site must be a number',
      }),
    ),
    device: z.enum([NotificationRuleDevice.BATTERY, NotificationRuleDevice.CALCULATOR]),
    condition_comparison_operator: z.string(),
    condition_value: z.preprocess(
      (value) => {
        if (typeof value === 'string' && value.trim() === '') {
          return undefined;
        }
        return Number(value);
      },
      z.number({
        required_error: 'Condition value is required',
        invalid_type_error: 'Condition value must be a number',
      }),
    ),
    comparison_field: z.string({
      required_error: 'Comparison field is required',
    }),
  })
  .superRefine((data, ctx) => {
    if (data.device === NotificationRuleDevice.BATTERY) {
      if (data.condition_value < 0 || data.condition_value > 100) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Condition value must be between 0 and 100 for battery devices',
          path: ['condition_value'],
        });
      }
    } else if (data.device === NotificationRuleDevice.CALCULATOR) {
      if (data.condition_value < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Condition value must be greater than or equal to 0 for calculator devices',
          path: ['condition_value'],
        });
      }
    }
  });

export default editRuleFormSchema;
