import { useState, PropsWithChildren } from 'react';
import { CenterX, Flex } from '@components/LayoutUtils';
import { Check, Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type ConfirmModalProps = PropsWithChildren<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTitle?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonCaption?: string;
  cancelButtonCaption?: string;
  noCancelButton?: boolean;
}>;

const ConfirmModal = ({
  open,
  setOpen,
  children,
  dialogTitle = 'Confirm action',
  onConfirm,
  onCancel,
  confirmButtonCaption,
  cancelButtonCaption,
  noCancelButton,
}: ConfirmModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm?.();
    handleClose();
  };

  const handleCancel = () => {
    onCancel?.();
    handleClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} sx={{ zIndex: 1300 }} onClose={handleClose}>
      <CenterX pl={3} pr={1} py={1} justifyContent="space-between">
        <Typography variant="body1" fontWeight="bold">
          {dialogTitle}
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <Close />
        </IconButton>
      </CenterX>
      <Divider />
      <Box px={3} py={2}>
        {children}
        <Flex p={0} pt={2} gap={1} justifyContent="flex-end">
          <Button
            data-testid="confirm-button-modal"
            onClick={handleConfirm}
            autoFocus
            variant="contained"
            startIcon={<Check />}
          >
            {confirmButtonCaption || 'Confirm'}
          </Button>
          {noCancelButton ? null : (
            <Button onClick={handleCancel} variant="secondaryFade" startIcon={<Close />}>
              {cancelButtonCaption || 'Cancel'}
            </Button>
          )}
        </Flex>
      </Box>
    </Dialog>
  );
};

type TriggerWithConfirmModalProps = ConfirmModalProps & {
  renderTrigger: (openModal: () => void) => any;
};

export const TriggerWithConfirmModal = ({
  renderTrigger,
  children,
  ...modalProps
}: Omit<TriggerWithConfirmModalProps, 'open' | 'setOpen'>) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      {renderTrigger?.(openModal)}
      <ConfirmModal open={open} setOpen={setOpen} {...modalProps}>
        {children}
      </ConfirmModal>
    </>
  );
};

export default ConfirmModal;
