import { useSyncExternalStore } from 'react';

const useVisibilityChangeSubscribe = (callback: (event: Event) => void) => {
  document.addEventListener('visibilitychange', callback);

  return () => {
    document.removeEventListener('visibilitychange', callback);
  };
};

const getVisibilityChangeSnapshot = () => document.visibilityState;

export function useVisibilityChange() {
  const visibilityState = useSyncExternalStore(
    useVisibilityChangeSubscribe,
    getVisibilityChangeSnapshot,
  );

  return visibilityState === 'visible';
}
