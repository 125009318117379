import { LocationOn } from '@mui/icons-material';
import { Box } from '@mui/material';

interface SiteCellProps {
  siteName: string;
}

export const SiteCell = ({ siteName }: SiteCellProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <LocationOn sx={{ mr: 1 }} />
    <span>{siteName}</span>
  </Box>
);
