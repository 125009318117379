import { Ref, forwardRef, useEffect } from 'react';
import { Alert } from '@appTypes/alerts/alert';
import { Alert as MuiAlert } from '@mui/material';
import { hideAlert, removeAlert } from '@redux/ducks/alerts/actionCreators';
import { SnackbarContent } from 'notistack';
import { useDispatch } from 'react-redux';

export const GlobalAlert = forwardRef(
  ({ id, message, variant, timeout }: Alert, ref: Ref<HTMLDivElement>) => {
    const dispatch = useDispatch();

    useEffect(
      () => () => {
        dispatch(removeAlert(id));
      },
      [dispatch, id],
    );

    const handleOnClose = () => {
      dispatch(hideAlert(id));
    };

    return (
      <SnackbarContent ref={ref}>
        <MuiAlert
          data-testid="alert"
          onClose={timeout === null ? handleOnClose : undefined}
          severity={variant}
        >
          {message}
        </MuiAlert>
      </SnackbarContent>
    );
  },
);
