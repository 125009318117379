import { useGetNotificationRulesQuery } from '@apiRtk/notificationRules';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import { NotificationRuleDto } from '@appTypes/models/notificationRule.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { Spinner } from '@components/Spinner';
import { DeviceCell } from '@components/TanStackTable/DeviceCell';
import { SiteCell } from '@components/TanStackTable/SiteCell';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { dictionary } from '@settings/dictionary';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';

const createColumns = (onEditClick: (id: number) => void): ColumnDef<NotificationRuleDto>[] => [
  {
    header: 'Device',
    accessorKey: 'device',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 0.25, minWidth: 100 }}>
        <DeviceCell
          formattedValue={row.original.device}
          siteId={row.original.site.id}
          isActive={row.original.is_active}
          row={row.original}
        />
      </div>
    ),
  },
  {
    header: 'Site',
    accessorKey: 'site_name',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 0.2, minWidth: 100 }}>
        <SiteCell siteName={row.original.site.name} />
      </div>
    ),
  },
  {
    header: 'Notification',
    accessorKey: 'description',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1, minWidth: 250 }}>
        <DetailCell onDetailClick={() => onEditClick(row.original.id)} detailBtnText="Edit">
          <span>{row.original.description}</span>
        </DetailCell>
      </div>
    ),
  },
];

interface NotificationRulesListProps {
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
  onEditClick: (id: number) => void;
}

export const NotificationRulesList = ({
  filters,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
  onEditClick,
}: NotificationRulesListProps) => {
  const columns = createColumns(onEditClick);

  const { data, isError, refetch, isLoading, isFetching } = useGetNotificationRulesQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {(isLoading || isFetching) && <Spinner />}
      {isError && (
        <AlertWithRefetch onRetryClick={refetch}>{dictionary.errorNoData}</AlertWithRefetch>
      )}
      {data && !isLoading && !isFetching && (
        <TanStackTable<NotificationRuleDto>
          columns={columns}
          rows={data.rules || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
