import { useGetEnergyFlowQuery } from '@apiRtk/imps';
import { FEATURE_FLAGS } from '@appTypes/common';
import FeatureFlagGuard from '@components/FeatureFlagGuard';
import CONFIG from '@config';
import { useVisibilityChange } from '@hooks/useVisibilityChange';
import { Alert, Box } from '@mui/material';
import { Diagram } from './EnergyFlow/index';
import { Diagram as DiagramOld } from './EnergyFlowOld/index';

const EnergyFlow = ({ ecdId }: { ecdId: string }) => {
  const documentVisible = useVisibilityChange();

  const { data, isError, isLoading } = useGetEnergyFlowQuery(ecdId!, {
    skip: !ecdId || !documentVisible,
    pollingInterval: CONFIG.ENERGY_FLOW.DIAGRAM_REFRESH_INTERVAL,
  });

  if (isError && !isLoading) {
    return (
      <Box p={5}>
        <Alert severity="error">Live diagram data not available, please try again later.</Alert>
      </Box>
    );
  }

  return (
    <>
      <FeatureFlagGuard flagName={FEATURE_FLAGS.DASHBOARD_NEW_ENERGY_FLOW}>
        <Diagram config={CONFIG.ENERGY_FLOW.DEVICES_CONFIG} loading={isLoading} data={data} />
      </FeatureFlagGuard>

      <FeatureFlagGuard flagName={FEATURE_FLAGS.DASHBOARD_NEW_ENERGY_FLOW} invert>
        <DiagramOld
          config={CONFIG.ENERGY_FLOW.DEVICES_CONFIG_OLD}
          loading={isLoading}
          data={data}
        />
      </FeatureFlagGuard>
    </>
  );
};

export default EnergyFlow;
