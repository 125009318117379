import { CenterX } from '@components/LayoutUtils';
import { Box } from '@mui/material';
import { convertUtcStringToLocalString, getTimeDifference } from '@utils/utils';
import { format, isSameDay, lightFormat } from 'date-fns';
import { palette } from 'styles/palette';

const TimeBadge = ({ date, green = false }: { date: Date; green?: boolean }) => (
  <Box
    sx={{
      color: palette.white,
      backgroundColor: green ? palette.success.dark : palette.error.dark,
      borderRadius: 4,
      py: 0.5,
      px: 1,
    }}
  >
    {format(date, 'HH:mm:ss')}
  </Box>
);

const dateFormater = ({
  startString,
  endString,
}: {
  startString: string;
  endString: Nullable<string>;
}) => {
  const dateFormat = 'dd.MM.yy';

  const now = new Date();

  const isActiveNotification = !endString;

  const startLocal = convertUtcStringToLocalString(startString);

  const start = new Date(startLocal);
  const end = endString ? new Date(convertUtcStringToLocalString(endString)) : now;

  const formattedDiff = getTimeDifference(start, end);
  const sameDay = isSameDay(end, start);
  const startDateFormatted = lightFormat(start, dateFormat);
  const endDateFormatted = lightFormat(end, dateFormat);

  return {
    start,
    end,
    isActiveNotification,
    formattedDiff,
    sameDay,
    startDateFormatted,
    endDateFormatted,
  };
};

interface DateTimeRangeProps {
  startString: string;
  endString: Nullable<string>;
}

const DateTimeRange = ({ startString, endString }: DateTimeRangeProps) => {
  const {
    isActiveNotification,
    formattedDiff,
    sameDay,
    startDateFormatted,
    start,
    end,
    endDateFormatted,
  } = dateFormater({
    startString,
    endString,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box color={palette.primary.main}>
          {isActiveNotification || sameDay
            ? startDateFormatted
            : `${startDateFormatted} - ${endDateFormatted}`}
        </Box>
        {isActiveNotification ? (
          <TimeBadge date={start} green />
        ) : (
          <CenterX>
            <TimeBadge date={start} />
            <CenterX color={palette.error.dark} flexWrap="nowrap" mx={1}>
              {formattedDiff}
            </CenterX>
            <TimeBadge date={end} />
          </CenterX>
        )}
      </Box>
    </Box>
  );
};

export default DateTimeRange;
