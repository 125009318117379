import { useEffect, useRef } from 'react';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { CreateAlert } from '@redux/ducks/alerts/actionTypes';
import { useDispatch } from 'react-redux';

type MutationResultErrorDetail = {
  detail: string;
};

type MutationResultError = {
  data: MutationResultErrorDetail;
};

type MutationResult = {
  isSuccess: boolean;
  isError: boolean;
  error?: MutationResultError | unknown;
};

type UseMutationAlertsConfig = {
  mutationResult: MutationResult;
  actionName?: string;
  messageSuccess?: string;
  messageError?: string;
  alertOptions?: Omit<CreateAlert, 'message'>;
  onSuccess?: () => void;
};

function isMutationResultError(error: unknown): error is MutationResultError {
  return typeof error === 'object' && error !== null && 'data' in error;
}

export const useMutationAlerts = (config: UseMutationAlertsConfig) => {
  const dispatch = useDispatch();
  const { mutationResult, actionName, messageSuccess, messageError, alertOptions, onSuccess } =
    config;

  const { isError, isSuccess, error } = mutationResult;

  const alertFiredRef = useRef(false);

  useEffect(() => {
    if (isSuccess && !alertFiredRef.current) {
      dispatch(
        createAlert({
          variant: 'success',
          ...(alertOptions || {}),
          message: messageSuccess || `${actionName}: Success`,
        }),
      );
      onSuccess?.();
      alertFiredRef.current = true;
    }
  }, [isSuccess, actionName, alertOptions, dispatch, messageSuccess, onSuccess]);

  useEffect(() => {
    if (isError) {
      let errorMessage = messageError || `${actionName}: An error occurred`;

      if (isMutationResultError(error)) {
        errorMessage = error.data.detail;
      }

      dispatch(
        createAlert({
          variant: 'error',
          ...(alertOptions || {}),
          message: errorMessage,
        }),
      );
      alertFiredRef.current = true;
    }
  }, [isError, actionName, alertOptions, dispatch, messageError, error]);
};
