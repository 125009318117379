import ApexCharts, { ApexOptions } from 'apexcharts';
import { palette } from 'styles/palette';
import { ApexSeries } from '../types';

const unitSymbols: { [key: string]: string } = {
  percent: '%',
  watt: 'W',
  kwatth: 'kWh',
  celsius: '°C',
  short: '',
  volt: 'V',
};

export const getUnitSymbol = (unit: string) => unitSymbols[unit] || unitSymbols.short;

const preciseRound = (n: number, decimals: number = 2): number => {
  const multiplier = 10 ** decimals;
  return Math.round((n + Number.EPSILON) * multiplier) / multiplier;
};

const SI_PREFIXES = ['', 'k', 'M', 'G', 'T', 'P'];

export const divideWithSI = (value: number, decimals: number = 1) => {
  const base = 1000;

  let quotient = Math.abs(value);
  let prefixIndex = 0;

  while (quotient >= base && prefixIndex < SI_PREFIXES.length - 1) {
    quotient /= base;
    prefixIndex += 1;
  }

  const resultValue = preciseRound(quotient * Math.sign(value), decimals);
  const prefix = SI_PREFIXES[prefixIndex];

  return {
    resultValue,
    prefix,
  };
};

export const parseBigNumber = (n: number, symbol: string, decimals?: number): [number, string] => {
  if (['%', '°C'].includes(symbol)) {
    return [preciseRound(n, decimals), symbol];
  }

  if (n === 0) {
    return [n, symbol];
  }

  const prefixIndex = Math.floor(Math.log10(Math.abs(n)) / 3);
  const prefix = SI_PREFIXES[Math.max(0, prefixIndex)];

  return [preciseRound(n / 10 ** (prefixIndex * 3), decimals), `${prefix}${symbol}`];
};

export const formatNumberWithUnit = (n: number, unit: string): string => {
  if (['percent', 'celsius', 'short'].includes(unit)) {
    return `${preciseRound(n)} ${getUnitSymbol(unit)}`.trimEnd();
  }

  let newNumber = n;

  const isKwh = unit === 'kwatth';
  let unitSymbol = getUnitSymbol(unit);

  if (isKwh) {
    // Note: has already kilos SI prefix so it is 1000 times greater!
    newNumber = n * 1000;
    unitSymbol = getUnitSymbol(unit).substring(1);
  }

  if (n === 0) {
    return `0 ${unitSymbol}`;
  }

  if (Math.abs(n) < 1000) {
    return `${preciseRound(n)} ${getUnitSymbol(unit)}`;
  }

  const [value, unitWithPrefix] = parseBigNumber(newNumber, unitSymbol);

  return `${value} ${unitWithPrefix}`;
};

export const getChartColors = () => Object.values(palette.graph);

export const isApple = () => {
  const expression = /(Mac|iPhone|iPod|iPad)/i;
  return expression.test(navigator.platform);
};

export const isControl = (event: any) => {
  if (isApple()) {
    return event.metaKey;
  }
  return event.ctrlKey;
};

export const getChartVisibleSeriesNames = (chartId: string) => {
  if (typeof ApexCharts === 'function' && ApexCharts.getChartByID(chartId)) {
    const chartInstance: any = ApexCharts.getChartByID(chartId);
    const { series } = chartInstance.w.config;
    const seriesNames = series.map((s: ApexSeries) => s.name);
    const { ancillaryCollapsedSeriesIndices, collapsedSeriesIndices } = chartInstance.w.globals;

    const hiddenSeriesIndices: number[] =
      ancillaryCollapsedSeriesIndices.concat(collapsedSeriesIndices);

    const hiddenSeries = hiddenSeriesIndices.map((seriesIndex) => seriesNames[seriesIndex]);

    const visibleSeries = (seriesNames as string[])?.filter((s) => !hiddenSeries.includes(s));
    return visibleSeries;
  }
  return [];
};

export const getChartInstance: any = (chartId: string) =>
  typeof ApexCharts === 'function' && ApexCharts.getChartByID(chartId);

function adjustMinMaxValues(min: number, max: number): number[] {
  const percentageThreshold = 0.1;

  if (min === 0 && max === 0) {
    return [-10, 10];
  }

  const diff = min * percentageThreshold;

  if (min === max) {
    return [min - diff, max + diff];
  }

  const adjustment = Math.abs(max - min) * 0.01;

  return [min - adjustment, max + adjustment];
}

const findFirstRightYaxisIndex = (arr: ApexYAxis[]): number => {
  const lastIndex = arr.reduceRight((acc, obj, index) => {
    if (obj?.show && acc === -1) {
      return index;
    }
    return acc;
  }, -1);

  return lastIndex;
};

export const rescaleYAxis = (chartId: string, options: ApexOptions) => {
  if (typeof ApexCharts === 'function' && ApexCharts.getChartByID(chartId)) {
    const chartInstance: any = ApexCharts.getChartByID(chartId);

    const { ancillaryCollapsedSeriesIndices, collapsedSeriesIndices, seriesLog, series } =
      chartInstance.w.globals;

    const hiddenSeriesIndices: number[] =
      ancillaryCollapsedSeriesIndices.concat(collapsedSeriesIndices);

    const indexes: Nullable<number>[] = series.map((__: any, index: number) => {
      if (!hiddenSeriesIndices.includes(index)) {
        return index;
      }
      return null;
    });

    const visibleSeriesIndices = indexes?.filter((index) => index !== null) as number[];

    const currentYAxisConfig = options?.yaxis as ApexYAxis[];

    const rightAxisIndex = findFirstRightYaxisIndex(currentYAxisConfig);

    const { leftValues, rightValues } = (seriesLog as number[][]).reduce<{
      leftValues: number[];
      rightValues: number[];
    }>(
      (result, value, index) => {
        if (visibleSeriesIndices.includes(index)) {
          if (index < rightAxisIndex) {
            result.leftValues.push(...value);
          } else {
            result.rightValues.push(...value);
          }
        }
        return result;
      },
      { leftValues: [], rightValues: [] },
    );

    let minValueLeft = 0;
    let maxValueLeft = 0;

    if (leftValues.length) {
      minValueLeft = Math.min(...leftValues);
      maxValueLeft = Math.max(...leftValues);
    }

    let minValueRight = 0;
    let maxValueRight = 0;

    if (leftValues.length) {
      minValueLeft = Math.min(...leftValues);
      maxValueLeft = Math.max(...leftValues);
    }
    if (rightValues.length) {
      minValueRight = Math.min(...rightValues);
      maxValueRight = Math.max(...rightValues);
    }
    const updatedYaxisConfig = currentYAxisConfig.slice();

    series.forEach((__: number, seriesIndex: number) => {
      const isPercentSerie = currentYAxisConfig[seriesIndex].title?.text === 'percent';

      let newMin = minValueLeft;
      let newMax = maxValueLeft;

      if (isPercentSerie) {
        return;
      }

      if (seriesIndex >= rightAxisIndex) {
        newMin = minValueRight;
        newMax = maxValueRight;
      }

      const [adjustedMin, adjustedMax] = adjustMinMaxValues(newMin, newMax);

      updatedYaxisConfig[seriesIndex] = {
        ...updatedYaxisConfig[seriesIndex],
        min: adjustedMin,
        max: adjustedMax,
        forceNiceScale: true,
      };
    });

    ApexCharts.exec(
      chartId,
      'updateOptions',
      {
        yaxis: updatedYaxisConfig,
      },
      false,
      true,
    );
  }
  return false;
};
