import { useState, useEffect, useRef } from 'react';
import { TypographyProps, Typography, Tooltip, TooltipProps } from '@mui/material';

type EllipsisTypographyProps = Omit<
  TypographyProps,
  'overflow' | 'textOverflow' | 'maxWidth' | 'children'
> & {
  maxWidth: number | string;
  children: string | number;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
};

export const EllipsisTypography = ({
  maxWidth,
  tooltipProps,
  children,
  whiteSpace = 'nowrap',
  ...props
}: EllipsisTypographyProps) => {
  const typographyRef = useRef<Nullable<HTMLSpanElement>>(null);

  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  const checkForOverflow = () => {
    const { current } = typographyRef;
    if (current) {
      const { offsetWidth, scrollWidth } = current;

      setShouldShowTooltip(offsetWidth < scrollWidth);
    }
  };

  useEffect(() => {
    checkForOverflow();
    window.addEventListener('resize', checkForOverflow);

    return () => {
      window.removeEventListener('resize', checkForOverflow);
    };
  }, [maxWidth, children]);

  return (
    <Tooltip {...(tooltipProps || {})} title={shouldShowTooltip ? children : ''}>
      <Typography
        {...props}
        ref={typographyRef}
        maxWidth={maxWidth}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace={whiteSpace}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
