import { StateFilter } from '@appTypes/helpers/filters';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { Toolbar } from '@components/Toolbar/Toolbar';
import { useRedirect } from '@hooks';
import { useFiltersTanStack } from '@hooks/useFiltersTanStack';
import { Settings } from '@mui/icons-material';
import NotificationIcon from '@mui/icons-material/Notifications';
import { Button, Grid } from '@mui/material';
import { NotificationList } from './NotificationList';

export const Notifications = () => {
  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    siteId,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setSiteId,
    setStateFilter,
  } = useFiltersTanStack({
    urlMirroringActive: true,
    sortingActive: false,
    pageSize: 5,
    sortingState: [{ id: 'start', desc: true }],
  });

  const redirect = useRedirect();

  return (
    <Grid>
      <TitleBar title="Notifications" pb={2} IconComponent={NotificationIcon} />
      <Button
        variant="outlined"
        color="primary"
        endIcon={<Settings />}
        onClick={() => {
          redirect((paths) => paths.notificationRules);
        }}
      >
        Setup rules
      </Button>
      <>
        <Toolbar
          showSiteSelect
          siteId={siteId}
          searchString={searchString}
          stateFilter={stateFilter || StateFilter.ACTIVE}
          onSetSiteId={setSiteId}
          onSearch={setSearchString}
          onSetStateFilter={setStateFilter}
        />
        <NotificationList
          filters={getFilters()}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={(value) => setSorting(value)}
          onPaginationChange={(value) => setPagination(value)}
        />
      </>
    </Grid>
  );
};

export default Notifications;
