import { StateFilter } from '@appTypes/helpers/filters';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { Toolbar } from '@components/Toolbar/Toolbar';
import { useFiltersTanStack } from '@hooks/useFiltersTanStack';
import { AccessAlarm } from '@mui/icons-material';
import { AlarmsList } from './AlarmsList';

const Alarms = () => {
  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    siteId,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setSiteId,
    setStateFilter,
  } = useFiltersTanStack({
    urlMirroringActive: true,
    sortingActive: false,
    pageSize: 5,
    sortingState: [{ id: 'last_occurrence', desc: false }],
  });

  return (
    <>
      <TitleBar title="Alarms" IconComponent={AccessAlarm} />
      <Toolbar
        showSiteSelect
        siteId={siteId}
        searchString={searchString}
        stateFilter={stateFilter || StateFilter.ACTIVE}
        onSetSiteId={setSiteId}
        onSearch={setSearchString}
        onSetStateFilter={setStateFilter}
      />
      <AlarmsList
        filters={getFilters()}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};

export default Alarms;
