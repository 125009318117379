// TODO: Populate this dictionary with all commonly used error messages, titles, and other text strings across the application.
// This centralizes content management, making it easier to maintain consistency.
// Note: In the future, this dictionary can be replaced or extended with a more sophisticated translation solution.

export const dictionary = {
  errorAddEcdToSite: 'Some error occured while adding ECD to site.',
  errorAppBehindFirewall:
    'Podium application was not able to contact our servers, this can be caused by firewall, please try open Podium from another network.',
  errorCommon: 'Some unexpected error occured, please try again later.',
  errorNoData: 'No data available. Please try again later.',
  errorOffline: 'You are currently offline. Please check your internet connection.',
};

export type DictionaryKeys = keyof typeof dictionary;
